<template>
  <content-wrapper>
    <h2>Services</h2>
    <h3>
      We're here to serve you. Below you'll find a brief description of our many
      services we offer at Narrow Gate.
    </h3>
    <!-- TODO: Cardboard recycling -->
    <!-- TODO: Plastic recycling -->
    <!-- TODO: Stretch film Recycling -->
    <Grid minmax="350px" style="grid-auto-rows: 1fr;">
      <article>
        <!-- NOTE: object data tags not resolved by vue loader automatically -->
        <object type="image/svg+xml" :data="require('@/assets/dollar.svg')">
          <img src="@/assets/dollar.svg" alt="US dollar">
        </object>
        <h4>Buy &amp; Sell</h4>
        <p>Pallets: new, custom built, refurbished, Can Pallets, Euros, GMA's, &amp; more</p>
        <p>Boards: new &amp; recycled</p>
        <p>Stringers: new &amp; recycled</p>
        <p>Cardboard, plastic, and stretch film bales</p>
      </article>
      <article>
        <object type="image/svg+xml" :data="require('@/assets/recycle.svg')">
          <img src="@/assets/recycle.svg" alt="Recyle symbol">
        </object>
        <h4>Recycle</h4>
        <p>We take the old &amp; make it new again from boards to pallets.</p>
      </article>
      <article>
        <object type="image/svg+xml" :data="require('@/assets/campfire.svg')">
          <img src="@/assets/campfire.svg" alt="Campfire">
        </object>
        <h4>Certified Heat Treating</h4>
        <p>
          Do you need your product heat treated? No worries! We've got that
          covered too. Our new and recycled facilities have that available.
        </p>
      </article>
      <article>
        <object type="image/svg+xml" :data="require('@/assets/us.svg')">
          <img src="@/assets/us.svg" alt="Continental United States">
        </object>
        <h4>Nationwide</h4>
        <p>
          Here at Narrow Gate, no distance is too far. We're nationwide so
          as to better serve you.
        </p>
      </article>
    </Grid>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';
import Grid from '@/components/Grid';

export default {
  name: 'Services',
  components: {
    ContentWrapper,
    Grid,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 30px;
  text-align: center;
  padding: 0 24px 12px 24px;
}

article {
  display: flex;
  flex-direction: column;
  padding: 20px;

  svg, img, object {
    height: 150px;
    text-align: center;
  }

  picture, h4 {
    text-align: center;
  }

  h4 {
    font-size: 24px;
    padding: 24px 0 24px 0;
  }

  p {
    margin-bottom: 0;
  }
}
</style>
